import React from 'react';
import { GetServerSidePropsContext, NextPage } from 'next';
import ScreenCbtHome from 'src/app.feature/academy/screen/ScreenCbtHome';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { convertExamQueryString } from 'app.feature/academy/module/convertExamQueryString';
import { TExamType } from 'src/app.type/examTypes';
import { TExamsBest } from 'src/app.type/examsBest';
import { TUserLikesExams } from 'src/app.type/userLikesExams';
import { TPaginationData } from 'src/app.type/common';
import { TExams } from 'src/app.type/exams';
import ExamApi from 'lib/api/exam';
import UserApi from 'lib/api/users';

type ExamList = {
  certificateExams: any[];
  publicCompanyExams: any[];
  corporateExams: any[];
};

type CbtPageProps = {
  title: string;
  examParentCategory: any;
  currentUser: any;
  examList: ExamList;
  likeList: any;
  currentHost: string;
};

const CbtPage: NextPage<CbtPageProps> = () => {
  return <ScreenCbtHome />;
};

export default CbtPage;

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const queryClient = new QueryClient();

  try {
    const examTypes = await queryClient.fetchQuery<TExamType[]>({
      queryKey: ['/apiV2/exam-types'],
      queryFn: () => ExamApi.getExamTypes(),
    });

    const bestExams = await queryClient.fetchQuery<TExamsBest>({
      queryKey: ['/apiV2/exams/best'],
      queryFn: () => ExamApi.getBestExam(),
    });

    const examList = await queryClient.fetchQuery<TPaginationData<TExams>>({
      queryKey: ['/apiV2/exams', convertExamQueryString(context.query)],
      queryFn: () => ExamApi.getExamList(convertExamQueryString(context.query)),
    });

    const examListByType = await Promise.all(
      examTypes?.map((item: TExamType) =>
        queryClient.fetchQuery<TPaginationData<TExams>>({
          queryKey: [`/apiV2/exams`, { examTypeIds: item.id, pageSize: 999 }],
          queryFn: () => ExamApi.getExamList({ examTypeIds: item.id, pageSize: 999 }),
        })
      )
    );

    const userLikes = await queryClient.fetchQuery<TUserLikesExams>({
      queryKey: ['/apiV2/user/me/likes/exams'],
      queryFn: () => UserApi.getUserLikes(),
    });
  } catch (error: any) {
    if (error?.response?.status !== 401) {
      console.error('#err-web-server: ', error.toString());
      throw error.response;
    }
  }

  const dehydratedState = dehydrate(queryClient);
  return {
    props: {
      title: '시험 CBT',
      dehydratedState,
    },
  };
}
